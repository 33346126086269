@use "../styles/vars";

html {
  scroll-padding-top: 8.25rem;

  @media screen and (max-width: vars.$first-header-break) {
    scroll-padding-top: 0;
  }
}

.siteHeader {
  background-color: vars.$dark;
  color: white;
  display: flex;
  height: 5.25rem;
  justify-content: space-between;
  gap: 1rem;
  line-height: 100%;
  align-items: center;
  padding-left: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  @media screen and (max-width: vars.$first-header-break) {
    flex-direction: column;
    padding: 1rem 0 0 0;
    position: static;
  }

  @media screen and (max-width: vars.$second-header-break) {
    height: auto;
  }

  h1 {
    font-size: 3rem;
    font-family: "Merriweather", serif;
    position: relative;

    @media screen and (max-width: vars.$first-header-break) {
      font-size: 2rem;
      z-index: 1;
      padding-top: 1rem;
    }

    &::after {
      $dimension: 100px;
      content: "";
      width: $dimension;
      height: $dimension;
      background-color: vars.$yellow;
      border-radius: 50%;
      left: calc(50% - $dimension/2);
      z-index: -1;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      position: absolute;
      top: -35px;

      @media screen and (max-width: vars.$first-header-break) {
        $dimension: 66px;
        width: $dimension;
        height: $dimension;
        left: calc(50% - $dimension/2);
        top: -8px;
      }
    }

    & + div {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      @media screen and (max-width: vars.$first-header-break) {
        position: absolute;
        width: calc(100% - 2rem);
      }

      @media screen and (max-width: vars.$second-header-break) {
        position: static;
        flex-wrap: wrap;
        padding: 0 0.5rem;
        margin-top: 1rem;
      }

      @media screen and (max-width: 532px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
