// Color vars
$yellow: #FAB239;
$pink: #EFA9AE;
$brown: #7A542E;
$purple: #A01A7D;
$red: #CA054D;
$dark: #172121;
$light: #d6d9da;

$extNav: ($brown, $pink, $purple, $red);

$article-aside: ($brown, $pink, $purple);

// Media query vars
$first-header-break: 876px;
$second-header-break: 700px;